import React from 'react'

import MapModule from './components/CityGuide/MapModule'
import RichTextRenderer from './components/RichTextRenderer'
import Navigation from './components/Navigation'
import Footer from './components/Footer'

class CBNRTop10 extends React.Component {
  //Render
  render() {
    return (
      <div
        id="article"
        className={'container-fluid ' + this.props.pages.backgroundColour + '-bg'}
        style={{ overflow: 'hidden' }} >
        {/* Global Navigation */}
        <Navigation
          navigationColor={this.props.pages.navigationColor}
          default={this.props.pages.navigationDefaultColor}
          menu={this.props.navigation}
          paths={this.props.paths || {}} />

        <RichTextRenderer
          content={this.props.pages.content}
          pages={this.props.pages}
          moduleOverrides={{
            MapModule: MapModule
          }}
          isCBNRArticle />
        
        {/* Footer */}
        <Footer
          footer={this.props.footer}
          location={this.props.location} />
      </div>
    )
  }
}

export default CBNRTop10
