import React from 'react';
import Layout, { buildProps } from './index.js';
import CBNRTop10 from './main-components/CBNRTop10';

const Component = (props) => {
  const {
    navigation,
    footer,
    searchedGuides,
    pagePath,
    pageId,
    pages,
    latestIds,
    paths
  } = buildProps(props)

  const addedProps = {
    pages,
    navigation,
    footer,
    searchedGuides,
    pagePath,
    pageId,
    paths
  }
  return <CBNRTop10
    {...addedProps}
    latestIds={latestIds} />
}

const Page = (props) => {
  return <Layout {...props}><Component {...props} /></Layout>
}

export default Page